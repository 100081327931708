import React from 'react'

function Intro() {
    return (
        <div className="intro">
            <p>Frontend-focused developer, goat fanatic, lover of popcorn</p>
        </div>
    )
}

export default Intro;