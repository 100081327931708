import React from 'react'
import { Route, Routes, NavLink } from "react-router-dom";

import Home from '../home';
import About from '../about';

function Navigation() {

    return (
        <>
            <ul className="nav-list flex">
                <li>
                    <NavLink
                        to="/"
                        end
                        className={({ isActive }) =>
                            isActive ? 'active-nav' : 'nav'
                        }
                    >
                        Home
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/about"
                        end
                        className={({ isActive }) =>
                            isActive ? 'active-nav' : 'nav'
                        }
                    >
                        About
                    </NavLink>
                </li>

            </ul>
            <Routes>
                <Route path="/" index element={<Home />} />
                <Route path="/about" element={<About />} />
            </Routes>
        </>
    )
}

export default Navigation;
