import React from 'react'

const ABOUT_ME_ONE =
    "I've always been interested in how technology can improve our lives. This interest got me my start as a Quality Assurance Engineer, giving me the opportunity to contribute to building quality software for our end users. From the beginning, I knew I wanted to be more involved on the building side of things, so I eventually made the switch into frontend software development."
const ABOUT_ME_TWO =
    "After working as a frontend software developer for almost 3 years, I would love to find a role at a company with an established culture of mentorship or sponsorship and with a proven focus on diversity, equity, & inclusion, particularly on hiring, retaining, and investing in people who have been historically excluded from tech."

function About() {
    return (
        <div className="flex direction--column about" style={{ width: '40%', margin: 'auto', marginTop: '2em', lineHeight: '1.4em' }}>
            <p>{ABOUT_ME_ONE}</p>
            <p style={{ marginTop: '1em' }}>{ABOUT_ME_TWO}</p>
        </div>
    )
}

export default About;