import React from 'react'

import Name from './name';

function Home() {
    return (
        <div className="flex direction--column home-container">
            <Name />
        </div>
    )
}

export default Home;