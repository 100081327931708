import React from 'react'
// import Me from './assets/me.png'
import MeBorder from './assets/me_border.png'
import Intro from './intro';

function Name() {
    return (
        <div className="name-wrapper">
            <div className="flex name-container">
                <img src={MeBorder} alt="me" />
                <div>
                    <h1>Anneliese Hernandez</h1>
                    <Intro />
                </div>
            </div>
        </div>
    )
}

export default Name;